<template>
  <b-container>
    <b-row>
      <b-col sm="auto" md="10" lg="8" xl="6">
        <h1>Rapporter</h1>
        <b-form inline>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="[{ text: 'Välj år...', value: '' }, '2021', '2022', '2023', '2024']"
            v-model="selectedYear"
            ></b-form-select>
          <b-button variant="primary" @click="loadData()" :disabled="selectedYear === ''">
            <span v-if="loading">
              <b-spinner small type="grow" ></b-spinner>
              Laddar...
            </span>
            <span v-else>Ladda data</span>
          </b-button>
        </b-form>
      </b-col>
    </b-row>

    <b-card v-if="membersInSelectedYear.length"
            title="Rapport till Svenska Budo och kampsportsförbundet">

      <ul>
        <li>Pojkar upp till 25: {{ inAge(0, 25, men).length }}</li>
        <li>Män över 25: {{ inAge(26, 200, men).length }}</li>
        <li>Flickor upp till 25: {{ inAge(0, 25, women).length }}</li>
        <li>Kvinnor över 25: {{ inAge(26, 200, women).length }}</li>
      </ul>
    Totalt antal: {{ membersInSelectedYear.length }}
    </b-card>

    <b-card v-if="membersInSelectedYear.length"
            title="Rapport till Svenska Ju-jutsuförbundet">

      <ul>
        <li>Flickor under 14: {{ inAge(0, 13, women).length }}</li>
        <li>Kvinnor 14 och över: {{ inAge(14, 200, women).length }}</li>
        <li>Totalt antal kvinnor: {{ women.length }}</li>
      </ul>
      <ul>
        <li>Pojkar under 14: {{ inAge(0, 13, men).length }}</li>
        <li>Män 14 och över: {{ inAge(14, 200, men).length }}</li>
        <li>Totalt antal män: {{ men.length }}</li>
      </ul>
    Totalt antal: {{ membersInSelectedYear.length }}
    </b-card>

    <b-card v-if="membersInSelectedYear.length"
            title="Rapport till Uppsala kommun">

      <ul>
        <li>Flickor 5-8: {{ inAge(5, 8, women).length }}</li>
        <li>Pojkar 5-8: {{ inAge(5, 8, men).length }}</li>
        <li>Totalt 5-8: {{ inAge(5, 8, women).length + inAge(5, 8, men).length }}</li>
      </ul>
      <ul>
        <li>Flickor 9-12: {{ inAge(9, 12, women).length }}</li>
        <li>Pojkar 9-12: {{ inAge(9, 12, men).length }}</li>
        <li>Totalt 9-12: {{ inAge(9, 12, women).length + inAge(9, 12, men).length }}</li>
      </ul>
      <ul>
        <li>Flickor 13-16: {{ inAge(13, 16, women).length }}</li>
        <li>Pojkar 13-16: {{ inAge(13, 16, men).length }}</li>
        <li>Totalt 13-16: {{ inAge(13, 16, women).length + inAge(13, 16, men).length }}</li>
      </ul>
      <ul>
        <li>Flickor 17-20: {{ inAge(17, 20, women).length }}</li>
        <li>Pojkar 17-20: {{ inAge(17, 20, men).length }}</li>
        <li>Totalt 17-20: {{ inAge(17, 20, women).length + inAge(17, 20, men).length }}</li>
      </ul>
      <ul>
        <li>Kvinnor 21-25: {{ inAge(21, 25, women).length }}</li>
        <li>Män 21-25: {{ inAge(21, 25, men).length }}</li>
        <li>Totalt 21-25: {{ inAge(21, 25, women).length + inAge(21, 25, men).length }}</li>
      </ul>
      <ul>
        <li>Kvinnor 60-99: {{ inAge(60, 99, women).length }}</li>
        <li>Män 60-99: {{ inAge(60, 99, men).length }}</li>
        <li>Totalt 60-99: {{ inAge(60, 99, women).length + inAge(60, 99, men).length }}</li>
      </ul>
    </b-card>

    Kontrolldata: {{ membersInSelectedYear.length }} st medlemmar<br />
    <ul v-if="membersInSelectedYear">
      <li v-for="(m, index) in membersInSelectedYear" :key="index">
        {{ m.name }} , {{ m.pnr }} , {{ m.age }} , {{ m.gender }} , {{ m.membershipNumber }}
      </li>
    </ul>

  </b-container>
</template>

<script>
  import { db } from '@/firebaseConfig.js'
  import { yearsBetweenDates } from '@/utils/date.js'
  import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'

  export default {
    data() {
      return {
        selectedYear: '',
        loading: false,
        members: [],
        membersByYear: {},
        membersInSelectedYear: []
      }
    },
    computed: {
      men() {
        return this.membersInSelectedYear.filter(m => m.gender === 'M')
      },
      women() {
        return this.membersInSelectedYear.filter(m => m.gender === 'F')
      },
    },
    methods: {
      async loadData() {
        this.loading = true
        try {
          await this.loadMembers()
          await this.loadMembersByYear(this.selectedYear)
        } catch (error) {
          console.log(`Failed to load data: ${error}`)
        }
        this.loading = false
      },
      async loadMembers() {
        if (this.members.length === 0) {
          const querySnapshot = await getDocs(collection(db, 'members'))
          const result = []
          querySnapshot.forEach(async doc => {
            const pnr = await this.fetchPersonalIdNumber(doc.id)
            result.push({id: doc.id, ...doc.data(), personalIdNumber: pnr })
          })
          this.members = result;
          console.log('Loaded members')
        } else {
          console.log('Members were already loaded')
        }
      },
      async fetchPersonalIdNumber(memberId) {
        const pnrDoc = await getDoc(doc(db, `members/${memberId}/private`, 'pnr'))
        if (pnrDoc.exists) {
          return pnrDoc.data().personalIdNumber
        }
      },
      async loadMembersByYear(year) {
        if (this.membersByYear[year] === undefined) {
          console.log('loading members by year')
          const result = []
          // Old version - membership fee has period equal to year
          let q = query(collection(db, 'fees'), where('period', '==', year))
          let querySnapshot = await getDocs(q)
          querySnapshot.forEach(doc => {
            const fee = doc.data()
            const m = this.members.find(m => m.id === fee.memberId)
            result.push({
              name: this.fullName(m),
              pnr: m.personalIdNumber,
              age: this.ageAt(new Date(year, 12, 31, 0, 0, 0, 0), m),
              gender: this.genderOf(m),
              membershipNumber: fee.number
            })
          })
          // New version - membership fee has period equal to the period when it was payed, and year equal to year
          q = query(collection(db, 'fees'), where('year', '==', year))
          querySnapshot = await getDocs(q)
          querySnapshot.forEach(doc => {
            const fee = doc.data()
            const m = this.members.find(m => m.id === fee.memberId)
            result.push({
              name: this.fullName(m),
              pnr: m.personalIdNumber,
              age: this.ageAt(new Date(year, 12, 31, 0, 0, 0, 0), m),
              gender: this.genderOf(m),
              membershipNumber: fee.number
            })
          })
          this.membersByYear[year] = result;
          console.log('Loaded fees')
        } else {
          console.log(`Fees for ${year} were already loaded`)
        }
        this.membersInSelectedYear = this.membersByYear[year]
      },
      fullName(member) {
        return `${member.firstName} ${member.lastName}`
      },
      birthdate(member) {
        const birthdate = member.birthdate ? member.birthdate : member.personalIdNumber.slice(0, 8)
        return new Date(birthdate.slice(0, 4) + "-" + birthdate.slice(4, 6) + "-" + birthdate.slice(6, 8))
      },
      ageAt(targetDate, member) {
        return yearsBetweenDates(this.birthdate(member), targetDate)
      },
      genderOf(member) {
        return parseInt(member.personalIdNumber.charAt(10)) % 2 === 0 ? 'F' : 'M'
      },
      inAge(minAge, maxAge, people) {
        return people.filter(p => p.age >= minAge && p.age <= maxAge)
      }
    }
  }

</script>
